var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: _vm.title,
        visible: _vm.dialogVisible,
        width: "520px",
        "before-close": _vm.cancel,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        top: "5%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      !_vm.resObj?.totalQty
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "muti-container",
            },
            [
              _c("div", { staticClass: "muban" }, [
                !_vm.uploadType
                  ? _c("div", { staticClass: "download-content" }, [
                      _c("span", [_vm._v("下载模版 ")]),
                      _c(
                        "div",
                        {
                          staticClass: "download",
                          staticStyle: { "margin-top": "10px" },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "btn flex_c_c",
                              on: { click: _vm.download },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  iconClass: "download",
                                  className: "icon el-icon--right",
                                },
                              }),
                              _c(
                                "a",
                                {
                                  staticStyle: { color: "#999999" },
                                  attrs: { type: "download" },
                                },
                                [_vm._v("下载模版 ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "company" },
                  [
                    _c("label", [
                      _c("span", [_vm._v("* ")]),
                      _vm._v("选择客户"),
                    ]),
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "320px" },
                        attrs: { filterable: "", placeholder: "请选择客户" },
                        model: {
                          value: _vm.companyId,
                          callback: function ($$v) {
                            _vm.companyId = $$v
                          },
                          expression: "companyId",
                        },
                      },
                      _vm._l(_vm.companyOptions, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "upload" },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      attrs: {
                        drag: "",
                        multiple: !!_vm.uploadType,
                        action: "#",
                        accept: `${
                          !_vm.uploadType ? ".xlsx,.xls" : ".pdf,.PDF"
                        }`,
                        "http-request": _vm.httpRequest,
                        "show-file-list": false,
                        "file-list": _vm.fileList,
                        "on-success": _vm.onSuccess,
                        "on-error": _vm.onError,
                        limit: 500,
                        "auto-upload": !!!_vm.uploadType,
                        "on-exceed": _vm.onExceed,
                        "on-change": _vm.changeFile,
                        "before-upload": _vm.beforeUpload,
                      },
                    },
                    [
                      _c("svg-icon", {
                        attrs: { iconClass: "upload", className: "icon" },
                      }),
                      _c("p", { staticClass: "p1" }, [
                        _vm._v("拖拽你的文件到此处"),
                      ]),
                      _c("p", { staticClass: "p2" }, [_vm._v("或")]),
                      _c("div", { staticClass: "btn" }, [_vm._v("点击上传")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "res",
            },
            [
              _c("div", [
                _c("p", [
                  _vm._v("共发现 " + _vm._s(_vm.resObj?.totalQty) + "条数据"),
                ]),
                _c("p", [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/order/success.png"),
                    },
                  }),
                  _c("span", { staticClass: "success" }, [
                    _vm._v(_vm._s(_vm.resObj?.successQty) + "条识别成功"),
                  ]),
                  _c("img", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: {
                      src: require("@/assets/images/order/error-1.png"),
                    },
                  }),
                  _c("span", { staticClass: "error" }, [
                    _vm._v(_vm._s(_vm.resObj?.failQty) + "条识别失败"),
                  ]),
                ]),
              ]),
            ]
          ),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c(
            "el-button",
            { staticClass: "btn btn-defalut", on: { click: _vm.cancel } },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }