<template>
  <el-dialog
    v-drag
    :title="title"
    :visible.sync="dialogVisible"
    width="520px"
    :before-close="cancel"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    top="5%"
  >
    <div v-if="!resObj?.totalQty" v-loading="loading" class="muti-container">
      <div class="muban">
        <div v-if="!uploadType" class="download-content">
          <span>下载模版 </span>
          <div class="download" style="margin-top: 10px">
            <el-button class="btn flex_c_c" @click="download">
              <svg-icon iconClass="download" className="icon el-icon--right" />
              <a
                style="color: #999999"
                type="download"
              >下载模版
              </a>
            </el-button>
          </div>
        </div>
        <div class="company">
          <label><span>* </span>选择客户</label>
          <el-select
            v-model="companyId"
            filterable
            style="width: 320px"
            placeholder="请选择客户"
          >
            <el-option
              v-for="item in companyOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="upload">
        <el-upload
          class="upload-demo"
          drag
          :multiple="!!uploadType"
          action="#"
          :accept="`${!uploadType ? '.xlsx,.xls' : '.pdf,.PDF'}`"
          :http-request="httpRequest"
          :show-file-list="false"
          :file-list="fileList"
          :on-success="onSuccess"
          :on-error="onError"
          :limit="500"
          :auto-upload="!!!uploadType"
          :on-exceed="onExceed"
          :on-change="changeFile"
          :before-upload="beforeUpload"
        >
          <svg-icon iconClass="upload" className="icon" />
          <p class="p1">拖拽你的文件到此处</p>
          <p class="p2">或</p>
          <div class="btn">点击上传</div>
        </el-upload>
      </div>
    </div>
    <div v-else v-loading="loading" class="res">
      <div>
        <p>共发现 {{ resObj?.totalQty }}条数据</p>
        <p>
          <img src="@/assets/images/order/success.png" />
          <span class="success">{{ resObj?.successQty }}条识别成功</span>
          <img
            style="margin-left: 20px"
            src="@/assets/images/order/error-1.png"
          />
          <span class="error">{{ resObj?.failQty }}条识别失败</span>
        </p>
      </div>
    </div>
    <div class="footer">
      <el-button class="btn btn-defalut" @click="cancel">取消</el-button>
      <!-- <el-button class="btn btn-primary" @click="ok">下一步</el-button> -->
    </div>
  </el-dialog>
</template>

<script>
let that
import {
  reprintLabel,
  checkReprintNumbers,
  uploadReprintLabel
} from '@/api/fee'
import config from '@/utils/config'
import { debounce } from '@/utils/common'
import { mapGetters } from 'vuex'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    uploadType: {
      type: Number,
      default: 0 // 0 上传订单 1 绑定面单
    }
  },
  data() {
    return {
      resObj: {},
      fileList: [],
      time: null,
      loading: false,
      config,
      companyId: ''
    }
  },
  computed: {
    ...mapGetters(['companyDrapdown']),
    companyOptions() {
      return this.companyDrapdown
    },
    title() {
      return !this.uploadType ? '上传订单' : '绑定面单'
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.companyId = ''
      }
    }
  },
  mounted() {
    that = this
  },
  methods: {
    // 下载模板
    download() {
      const elink = document.createElement('a')
      elink.style.display = 'none'
      elink.href = `/backEnd/api/${config.supplyCode}/File/downLoadTemplate?guid=e1ffd9d4ccc24fbf841fd9b05304173f`
      document.body.appendChild(elink)
      elink.click()
      URL.revokeObjectURL(elink.href)
      document.body.removeChild(elink)
    },
    onSuccess(res) {
      if (res.success) {
        console.log(res)
        // this.$emit('ok', res.data)
        this.resObj = res.data
      } else {
        this.$message.error(res.errorMessage)
      }
    },
    onError(err) {
      this.loading = false
      console.log(err)
    },
    cancel() {
      this.resObj = {}
      this.fileList = []
      this.$emit('cancel')
    },
    httpRequest(e) {
      if (!this.companyId) {
        return false
      }
      if (!this.upload) {
        console.log('fileList', this.fileList)
        this.uploadOrder(e.file)
      }
    },
    async uploadOrder(file) {
      const formData = new FormData()
      formData.append('file', file)
      try {
        const result = await reprintLabel(formData, this.companyId)
        this.loading = false
        if (result.data.success) {
          if (!result.data.data.totalQty) {
            this.$message.warning('无数据')
          }
          this.resObj = result.data.data
        } else {
          this.$message.error(result.data.errorMessage)
        }
      } catch (error) {
        this.loading = false
        this.$message.error('上传失败')
      }
    },
    beforeUpload(file) {
      if (!this.companyId) {
        this.$message.error('请选择客户')
        return false
      }
      this.loading = true
      console.log('file', file)
    },
    onExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 500 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      )
    },
    changeFile: debounce((file, fileList) => {
      if (!that.uploadType) return
      that.loading = true
      console.log('changeFile', file, fileList)
      let sumSize = 0
      fileList.forEach((file) => {
        sumSize += file.size
      })
      if (sumSize > 100 * 1024 * 1024) {
        // 大于100M
        that.$message.warning('当前所选文件大小之和超过100MB')
        return
      } else {
        console.log('sumSize', sumSize)
        that.checkFile(fileList)
      }
    }, 1000),
    async checkFile(fileList) {
      console.log('fileList', fileList)
      const arr = fileList.map((a) => {
        return a.name
      })
      console.log('arr', arr)
      try {
        const res = await checkReprintNumbers(arr, this.companyId)
        if (res.success) {
          const newFileList = fileList.filter((file) => {
            return res.data.indexOf(file.name) > -1
          })
          console.log('newFileList', newFileList)
          const arr = []
          if (newFileList.length > 10) {
            for (let i = 0; i < newFileList.length; i += 10) {
              arr.push(newFileList.slice(i, i + 10)) // 每10项分成一组
            }
          } else {
            arr.push(newFileList)
          }
          console.log('arr', arr)
          this.uploadFile(arr)
        } else {
          this.loading = false
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 上传面单
    async uploadFile(fileLists) {
      const pormise = fileLists.map(async(files) => {
        const formData = new FormData()
        files.forEach((file) => {
          formData.append('formFiles', file.raw)
        })
        return await uploadReprintLabel(formData, this.companyId)
      })
      Promise.all(pormise).then((res) => {
        const obj = {
          totalQty: 0,
          successQty: 0,
          failQty: 0
        }
        res.forEach((result) => {
          if (!result.data.success) {
            this.$message.error(result.data.errorMessage)
          } else {
            obj.failQty += result.data.data.failQty
            obj.successQty += result.data.data.successQty
            obj.totalQty += result.data.data.totalQty
          }
          this.resObj = obj
          this.loading = false
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header {
  border: 0;
}
/deep/ .el-dialog__body {
  padding-top: 0;
}
.muti-container {
  width: calc(100% - 40px);
  height: auto;
  margin: 0 auto;
  .muban {
    width: 440px;
    height: auto;
    background: #F7F7F7;
    border-radius: 6px 6px 6px 6px;
    padding: 20px 30px;
    box-sizing: border-box;
    span {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
    }
    .icon {
      font-size: 20px;
      margin-left: 4px;
      color: #999999;
    }
    .btn {
      width: 140px;
      height: 32px;
      background: #FFFFFF;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #EEEEEE;
      box-sizing: border-box;
    }
    .download-content {
      margin-bottom: 20px;
    }
    .company {
      span {
        color: #FF7444;
      }
    }
  }
  .upload {
    width: 440px;
    height: 200px;
    background: #f8fbff;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    margin-top: 16px;
    .icon {
      font-size: 40px;
      margin: 26px auto 0;
      color: #2395F3;
    }
    .p1 {
      height: 19px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 24px;
      text-align: center;
      margin-top: 10px;
    }
    .p2 {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 24px;
      text-align: center;
      margin-top: 10px;
    }
    .btn {
      width: 120px;
      height: 40px;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #2395f3;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #2395f3;
      line-height: 40px;
      text-align: center;
      margin: 10px auto 0;
    }
    /deep/ .upload-demo {
      width: 100%;
      height: 100%;
    }
    /deep/.el-upload {
      width: 100%;
      height: 100%;
    }
    /deep/.el-upload-dragger {
      width: 100%;
      height: 100%;
    }
    /deep/.el-upload-dragger {
      background: #F7F7F7;
      border: 0;
    }
  }
}
.footer {
  text-align: center;
  margin-top: 24px;
  .btn {
    width: 120px;
    height: 40px;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
    & + .btn {
      margin-left: 16px;
    }
  }
  .btn-defalut {
    color: #999999;
    background: #EEEEEE;
    border: 0;
  }
  .btn-primary {
    background: #2395f3;
    color: #ffffff;
  }
}
.res {
  width: 440px;
  height: 200px;
  background: #F7F7F7;
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  p {
    width: 100%;
    text-align: center;
    height: 19px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 19px;
    & + p {
      margin-top: 16px;
    }
    .success {
      color: #35ba83;
    }
    .error {
      color: #fe453d;
    }
    img {
      vertical-align: -2px;
      margin-right: 4px;
    }
  }
}
</style>
