<template>
  <div class="contents">
    <div class="opt">
      <div class="import import-1" @click="upload(0)">
        <img src="@/assets/images/order/upload-1.png" alt="" />上传订单
      </div>
      <div class="import" @click="upload(1)">
        <img src="@/assets/images/order/binding.png" alt="" />绑定面单
      </div>
      <BaseFilter
        ref="operate"
        :width="480"
        :isActive="isActive"
        @sendFilter="sendFilter"
        @reset="reset"
      >
        <div class="filter">
          <el-form
            ref="form"
            :model="filterList"
            label-width="76px"
          >
            <el-form-item label="时间">
              <el-date-picker
                v-model="filterList.date"
                style="width: 320px"
                type="daterange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                unlink-panels
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="状态">
              <el-select
                v-model="filterList.status"
                style="width: 320px"
                placeholder="请选择状态"
              >
                <el-option
                  v-for="item in config.scanStatusOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="客户">
              <el-select
                v-model="filterList.companyId"
                filterable
                style="width: 320px"
                placeholder="请选择客户"
              >
                <el-option
                  v-for="item in companyDrapdown"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="新/旧单号">
              <base-muti-input
                ref="baseMutiInput"
                v-model="filterList.search"
                style="width: 320px"
              ></base-muti-input>
            </el-form-item>
          </el-form>
        </div>
      </BaseFilter>
    </div>
    <div class="data">
      <BaseTable
        v-if="height !== 0"
        :height="height"
        :tableData="tableData"
        :pageIndex="pager.pageIndex"
        :pageSize="pager.pageSize"
        :loading="loading"
        :isPager="true"
        @handlePageChange="handlePageChange"
        @handleSelectionChange="handleSelectionChange"
        @sortChange="sortChange"
      >
        <template slot="statusName" slot-scope="row">
          <span :class="{'error': row.scope.status === 2}">{{ row.scope.statusName }}</span>
          <el-tooltip v-if="row.scope.status === 2" class="item" effect="dark" :content="row.scope.reason" placement="bottom">
            <img style="vertical-align: -4px;" src="@/assets/images/order/wenhao.png" />
          </el-tooltip>
        </template>
        <template slot="opt" slot-scope="row">
          <img v-if="row.scope.exsitsLabel" title="查看" class="view" src="@/assets/images/order/viewOrder.png" @click="view(row.scope)" />
        </template>
        <!-- <template slot="custom-total">
          <div class="custom-total">
            <span
              class="select"
            >已选择 <span>{{ selectionItem.length }}</span> 项</span
            >
            <div
              class="upload flex_c_c"
              :class="{ active: selectionItem.length }"
              @click="deleteOrder"
            >
              <svg-icon className="icon" iconClass="deleteBill" />删除
            </div>
          </div>
        </template> -->
      </BaseTable>
    </div>
    <ScanUploadDialog
      :dialogVisible="dialogVisible"
      :uploadType="uploadType"
      @cancel="cancel">
    </ScanUploadDialog>
  </div>
</template>

<script>
// const Operate = () => import('@/components/optGroup/Operate')
const BaseFilter = () => import('@/components/filter/BaseFilter')
const BaseTable = () => import('@/components/table/BaseTable')
const BaseMutiInput = () => import('@/components/base/BaseMutiInput')
import ScanUploadDialog from './components/ScanUploadDialog'
import { getReprintLabelPageList, deleteReprintLabel } from '@/api/fee'
import config from '@/utils/config'
import { mapGetters } from 'vuex'
import { myMixins } from '@/mixins'
export default {
  name: 'Scan',
  components: { BaseTable, BaseFilter, BaseMutiInput, ScanUploadDialog },
  filters: {
    formatterStatus(status) {
      return config.workBillStatusOptions.find((a) => a.value === status).label
    }
  },
  mixins: [myMixins],
  data() {
    return {
      pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }]
        },
      config,
      tableData: {
        fileds: [
          {
            filed: 'companyName',
            label: '客户',
            width: '230'
          },
          {
            filed: 'newTrackingNumber',
            label: '新单号',
            isCopy: true,
            width: '230',
            align: 'left'
          },
          {
            filed: 'oldTrackingNumber',
            label: '旧单号',
            isCopy: true,
            width: '230',
            align: 'left'
          },
          {
            filed: 'exsitsLabel',
            label: '是否存在面单',
            formatter: row => {
              return row.exsitsLabel ? '是' : '否'
            },
            width: '130'
          },
          {
            filed: 'statusName',
            label: '状态',
            isCustom: true
          },
          {
            filed: 'scanOn',
            label: '扫描时间',
            isSort: true,
            formatter: (row) =>
              row.scanOn ? row.scanOn.slice(0, row.scanOn.length - 3) : '',
            width: '150'
          },
          {
            filed: 'scanName',
            label: '扫描人'
          },
          {
            filed: 'createdOn',
            label: '创建时间',
            isSort: true,
            formatter: (row) =>
              row.createdOn ? row.createdOn.slice(0, row.createdOn.length - 3) : '',
            width: '150'
          },
          {
            filed: 'createdName',
            label: '创建人'
          },
          {
            filed: 'opt',
            label: '操作',
            width: '150',
            isCustom: true
          }
        ],
        mutiCheck: true,
        columns: [],
        total: 0
      },
      loading: false,
      isActive: false,
      pager: {
        pageIndex: 1,
        pageSize: 20
      },
      filterList: {
        date: '',
        search: '',
        status: '',
        companyId: ''
      },
      sortObj: {
        sortField: 'updatedOn',
        sortBy: 1
      },
      dialogVisible: false,
      selectionItem: [],
      uploadType: 0,
      height: 0
    }
  },
  computed: {
    ...mapGetters(['companyDrapdown'])
  },
  created() {
    this.filterList.date = this.getRecentMonthDate(1)
    this.getList(this.pager)
  },
  mounted() {
    this.height = document.querySelector('.data').offsetHeight - 42
    if (!this.companyDrapdown.length) {
      this.$store.dispatch('getCompanyDrapdown')
    }
  },
  methods: {
    handlePageChange(pager) {
      this.pager = pager
      this.getList(pager)
    },
    async getList(params) {
      this.loading = true
      try {
        params = {
          ...params,
          queryParam: {
            ...this.sortObj,
            ...this.formatterFilter()
          }
        }
        const res = await getReprintLabelPageList(params)
        this.loading = false
        if (res.success) {
          this.tableData.columns = res.data.list
          this.tableData.total = res.data.total
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    ok() {
      this.initPage()
      this.dialogVisible = false
    },
    reset() {
      this.$refs.baseMutiInput.reset()
      this.filterList = {
        date: this.getRecentMonthDate(1),
        search: '',
        status: '',
        companyId: ''
      }
      this.isActive = false
    },
    formatterFilter() {
      return {
        status: this.filterList.status !== undefined ? this.filterList.status : undefined,
        search: this.filterList.search && this.filterList.search.length > 0 ? this.filterList.search.join(',') : undefined,
        startDate: this.filterList.date ? this.filterList.date[0] : undefined,
        endDate: this.filterList.date ? this.filterList.date[1] : undefined,
        companyId: this.filterList.companyId || undefined
      }
    },
    sendFilter() {
      if (
        this.filterList.companyId !== '' ||
        this.filterList.workOrderTypeId !== '' ||
        this.filterList.workOrderItemId !== '' ||
        this.filterList.priorityLevel !== '' ||
        this.filterList.status
      ) {
        this.isActive = true
      } else {
        this.isActive = false
      }
      this.pager = {
        pageIndex: 1,
        pageSize: 20
      }
      this.getList({
        pageIndex: 1,
        pageSize: 20
      })
    },
    initPage() {
      this.pager = {
        pageIndex: 1,
        pageSize: 20
      }
      this.getList({
        pageIndex: 1,
        pageSize: 20
      })
    },
    upload(type) {
      this.uploadType = type
      this.dialogVisible = true
    },
    cancel() {
      this.initPage()
      this.dialogVisible = false
    },
    // 近N月 - new Date()
    getRecentMonthDate(n) {
      //   let result = ''
      const datenow = new Date()
      const dateend =
        datenow.getFullYear().toString() +
        '-' +
        (datenow.getMonth() + 1).toString() +
        '-' +
        datenow.getDate().toString()
      datenow.setMonth(datenow.getMonth() - n)
      const dyear = datenow.getFullYear()
      let dmonth = datenow.getMonth() + 1
      dmonth = dmonth < 10 ? 0 + dmonth : dmonth
      const dday = datenow.getDate()
      const datestart =
        dyear.toString() + '-' + dmonth.toString() + '-' + dday.toString()
      //   result += datestart + ','
      //   result += dateend
      return [datestart, dateend]
    },
    handleSelectionChange(items) {
      this.selectionItem = items
    },
    deleteOrder() {
      if (this.selectionItem.length === 0) {
        this.$message.warning('请勾选订单')
        return
      }
      this.$confirm('此操作将取消该订单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const arr = this.selectionItem.map((item) => {
            return item.id
          })
          deleteReprintLabel(arr).then((res) => {
            if (res.success) {
              this.$message.success('删除成功')
              this.initPage()
            } else {
              this.$message.error(res.errorMessage)
            }
          })
        })
        .catch(() => {})
    },
    view(row) {
      const obj = {
        trackingNmber: row.oldTrackingNumber,
        labeFormat: 'PDF',
        isPrint: true,
        type: 2
      }
      const routeUrl = this.$router.resolve({
        path: '/pdf',
        query: { ...obj }
      })
      window.open(routeUrl.href, '_blank')
    },
    sortChange(params) {
      this.sortObj = {
        sortField: params.prop,
        sortBy: params.order
      }
      this.getList(this.pager)
    }
  }
}
</script>

<style lang="less" scoped>
.contents {
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: auto;
  padding: 0 16px;
  box-sizing: border-box;
  position: relative;
}
.data {
  width: 100%;
  height: calc(100% - 65px);
  z-index: 1;
  & .fTable {
      border-top: 1px solid #ebeef5;
      box-sizing: border-box;
    }
  .tag {
    padding: 6px 11px;
  }
}
.optimgbox {
  display: flex;
  justify-content: center;
  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 15px;
  }
  .record {
    position: relative;
    width: 20px;
    height: 20px;
    margin-right: 15px;
  }
  .newPoint {
    position: absolute;
    width: 8px;
    height: 8px;
    background: #fe453d;
    border-radius: 50%;
    left: 12px;
    top: 0;
  }
}
.messimg::before {
  content: " ";
  width: 8px;
  height: 8px;
  background: #fe453d;
  opacity: 1;
  position: absolute;
  top: 15px;
  left: 50.5%;
  border-radius: 50%;
  z-index: 1;
}
.opt {
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .import {
    width: 112px;
    height: 32px;
    background: #35ba83;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    margin-right: 8px;
    img {
      width: 20px;
      height: 20px;
    }
  }
  .import-1 {
    background: #2395f3;
  }
}
.view {
  line-height: 30px;
  cursor: pointer;
  color:#2395f3
}
.custom-total {
  position: absolute;
  bottom: 0;
  left: 20px;
  display: flex;
  align-items: center;
  color: #b8c3de;
  height: 42px;
  font-weight: 400;
  font-size: 14px;
  z-index: 1000;
  .select {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #b8c3de;
    span {
      color: #2395f3;
    }
  }

  .upload {
    width: 84px;
    height: 32px;
    margin-left: 27px;
    border-radius: 4px;
    border: 1px solid #dbe4f2;
    box-sizing: border-box;
    cursor: pointer;
    &.active {
      border-color: #35ba83;
      color: #35ba83;
    }
    .icon {
      font-size: 20px;
      margin-right: 4px;
    }
  }
}
.error {
  color: #FE453D;
}
</style>
