var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "contents" },
    [
      _c(
        "div",
        { staticClass: "opt" },
        [
          _c(
            "div",
            {
              staticClass: "import import-1",
              on: {
                click: function ($event) {
                  return _vm.upload(0)
                },
              },
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/order/upload-1.png"),
                  alt: "",
                },
              }),
              _vm._v("上传订单 "),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "import",
              on: {
                click: function ($event) {
                  return _vm.upload(1)
                },
              },
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/order/binding.png"),
                  alt: "",
                },
              }),
              _vm._v("绑定面单 "),
            ]
          ),
          _c(
            "BaseFilter",
            {
              ref: "operate",
              attrs: { width: 480, isActive: _vm.isActive },
              on: { sendFilter: _vm.sendFilter, reset: _vm.reset },
            },
            [
              _c(
                "div",
                { staticClass: "filter" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: { model: _vm.filterList, "label-width": "76px" },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "时间" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "320px" },
                            attrs: {
                              type: "daterange",
                              "range-separator": "-",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              "value-format": "yyyy-MM-dd",
                              "unlink-panels": "",
                              "picker-options": _vm.pickerOptions,
                            },
                            model: {
                              value: _vm.filterList.date,
                              callback: function ($$v) {
                                _vm.$set(_vm.filterList, "date", $$v)
                              },
                              expression: "filterList.date",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "状态" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "320px" },
                              attrs: { placeholder: "请选择状态" },
                              model: {
                                value: _vm.filterList.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterList, "status", $$v)
                                },
                                expression: "filterList.status",
                              },
                            },
                            _vm._l(
                              _vm.config.scanStatusOption,
                              function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "320px" },
                              attrs: {
                                filterable: "",
                                placeholder: "请选择客户",
                              },
                              model: {
                                value: _vm.filterList.companyId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterList, "companyId", $$v)
                                },
                                expression: "filterList.companyId",
                              },
                            },
                            _vm._l(_vm.companyDrapdown, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "新/旧单号" } },
                        [
                          _c("base-muti-input", {
                            ref: "baseMutiInput",
                            staticStyle: { width: "320px" },
                            model: {
                              value: _vm.filterList.search,
                              callback: function ($$v) {
                                _vm.$set(_vm.filterList, "search", $$v)
                              },
                              expression: "filterList.search",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "data" },
        [
          _vm.height !== 0
            ? _c("BaseTable", {
                attrs: {
                  height: _vm.height,
                  tableData: _vm.tableData,
                  pageIndex: _vm.pager.pageIndex,
                  pageSize: _vm.pager.pageSize,
                  loading: _vm.loading,
                  isPager: true,
                },
                on: {
                  handlePageChange: _vm.handlePageChange,
                  handleSelectionChange: _vm.handleSelectionChange,
                  sortChange: _vm.sortChange,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "statusName",
                      fn: function (row) {
                        return [
                          _c(
                            "span",
                            { class: { error: row.scope.status === 2 } },
                            [_vm._v(_vm._s(row.scope.statusName))]
                          ),
                          row.scope.status === 2
                            ? _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: row.scope.reason,
                                    placement: "bottom",
                                  },
                                },
                                [
                                  _c("img", {
                                    staticStyle: { "vertical-align": "-4px" },
                                    attrs: {
                                      src: require("@/assets/images/order/wenhao.png"),
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "opt",
                      fn: function (row) {
                        return [
                          row.scope.exsitsLabel
                            ? _c("img", {
                                staticClass: "view",
                                attrs: {
                                  title: "查看",
                                  src: require("@/assets/images/order/viewOrder.png"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.view(row.scope)
                                  },
                                },
                              })
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1862613241
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("ScanUploadDialog", {
        attrs: { dialogVisible: _vm.dialogVisible, uploadType: _vm.uploadType },
        on: { cancel: _vm.cancel },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }